<template>
  <div class="mb-main__horarios" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#app';

const route = useRoute();
const router = useRouter();

if (!route.params.servicio) {
  router.push('/biotren');
}
</script>

<style lang="scss">
.mb-main__horarios {
  min-height: 100vh;
  @apply px-16 pt-32 pb-56 mb-40;
}
.mb-horarios__horaopts {
  @apply flex justify-between mb-36;
}

.mb-estaciones__filtro-opciones {
  @apply flex justify-end mb-16;

  button {
    @apply ml-4;
  }
}
.mb-horarios__tarifas-container {
  @apply mb-12;
  @supports (display: grid) {
    @apply grid grid-cols-3;
  }
  @supports not (display: grid) {
    @apply flex;

    > .mb-tarifa__saldo {
      @apply w-1/3;
    }
  }
}
.mb-tarifa__saldo {
  @apply text-center leading-none border-r border-gray-300;

  &:last-of-type {
    @apply border-r-0;
  }

  span {
    @apply text-xs uppercase mb-4 block tracking-wider;
  }
  h3 {
    @apply text-2xl flex items-center justify-center;
    small {
      @apply mr-4;
    }
  }
  svg {
    @apply text-gray-500;
  }
}
.dark-mode .mb-tarifa__saldo {
  @apply border-gray-700;
}
.mb-horarios__mi-saldo {
  background: hsla(35, 84%, 50%, 0.2);
  border-color: hsla(35, 84%, 50%, 0.3);
  color: hsl(35, 100%, 23%);

  @apply p-8 px-12 rounded-sm border mb-24 flex items-center;
  span {
    @apply uppercase text-xs tracking-wider;
  }
  h4 {
    @apply font-semibold ml-auto;
  }

  button {
    border-color: hsla(35, 84%, 50%, 0.3);
    @apply ml-8 px-4;

    svg {
      @apply ml-0;
    }
  }
}
.dark-mode {
  .mb-horarios__mi-saldo {
    color: hsl(35, 100%, 90%);
  }
}

.mb-filtro__estaciones {
  @apply flex items-center justify-end mb-16;

  span {
    @apply text-xs uppercase tracking-wider text-gray-700 mr-4;
  }
  button {
    @apply ml-4;
  }
}
.dark-mode .mb-filtro__estaciones {
  span {
    @apply text-gray-300;
  }
}
</style>
